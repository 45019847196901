<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Коммерческие условия')"
    :fieldLand="fieldLang"
    :keyTable="'financialConditionId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :prefixEdit="`/commercialTerms`"
    :prefixShow="`/commercialTerms`"
    :linkCreate="`/commercialTerms/create`"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_COMPANY} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
export default {
  name: "CommercialTermsPage",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: FIELD_LANG_COMPANY,
      apiName: API_METHODS.GET_ALL_FINANCIAL_CONDITION,
      apiDeleteName: API_METHODS.DELETE_FINANCIAL_CONDITION
    };
  },
  computed: {
    nameRoleEntity() {
      return NAME_OBJECT.commercialTerms
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_COMPANY)
      return res;
    },
    deleteParams(id) {
      return { id: id };
    },
  }
};
</script>

